import * as yup from 'yup';
import i18n from '../../i18n/i18n';
import { EngagementModel } from './projectFormModel';

const t = i18n.t;

const translations = {
    projectSchema_clientName_isRequired: t(
        'projectSchema_clientName_isRequired',
    ),
    projectSchema_endDate_isRequired: t('projectSchema_endDate_isRequired'),
    projectSchema_endDate_mustOccur: t('projectSchema_endDate_mustOccur'),
    projectSchema_projectName_isRequired: t(
        'projectSchema_projectName_isRequired',
    ),
    projectSchema_startDate_isRequired: t('projectSchema_startDate_isRequired'),
};

const getValidationSchema = () => {
    return yup.object({
        activeProjectSwitch: yup.boolean(),
        businessId: yup.string().max(256),
        clientName: yup
            .string()
            .max(256)
            .required(translations.projectSchema_clientName_isRequired),
        commentary: yup.string(),
        engagementManager: yup.object().shape({
            userId: yup.string(),
            upn: yup.string(),
        }),
        endDate: yup
            .date()
            .typeError(translations.projectSchema_endDate_isRequired)
            .required(translations.projectSchema_endDate_isRequired)
            .min(
                yup.ref('startDate'),
                translations.projectSchema_endDate_mustOccur,
            ),
        locations: yup.array().of(yup.string()),
        dataPointIds: yup.array().of(yup.string()),
        projectName: yup
            .string()
            .max(256)
            .required(translations.projectSchema_projectName_isRequired),
        startDate: yup
            .date()
            .typeError(translations.projectSchema_startDate_isRequired)
            .required(translations.projectSchema_startDate_isRequired),
        engagementModel: yup
            .string()
            .oneOf<EngagementModel>([
                '',
                'CAPACITY',
                'OUTCOME',
                'COMANAGED',
                'ASSESSMENT',
            ]),
        scrumMaster: yup.object().shape({
            userId: yup.string(),
            upn: yup.string(),
        }),
        practicePartner: yup.object().shape({
            userId: yup.string(),
            upn: yup.string(),
        }),
        projectLinks: yup.array().of(
            yup.object().shape({
                id: yup.number(),
                name: yup.string(),
                url: yup.string(),
            }),
        ),
    });
};

export default getValidationSchema;
