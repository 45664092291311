// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Restriction
 */
export interface Restriction {
    /**
     *
     * @type {string}
     * @memberof Restriction
     * @deprecated
     */
    readAccessRole?: string;
    /**
     *
     * @type {string}
     * @memberof Restriction
     * @deprecated
     */
    writeAccessRole?: string;
    /**
     *
     * @type {Set<string>}
     * @memberof Restriction
     */
    readAccessRoles?: Set<string>;
    /**
     *
     * @type {Set<string>}
     * @memberof Restriction
     */
    writeAccessRoles?: Set<string>;
}

/**
 * Check if a given object implements the Restriction interface.
 */
export function instanceOfRestriction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RestrictionFromJSON(json: any): Restriction {
    return RestrictionFromJSONTyped(json, false);
}

export function RestrictionFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Restriction {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        readAccessRole: !exists(json, 'readAccessRole')
            ? undefined
            : json['readAccessRole'],
        writeAccessRole: !exists(json, 'writeAccessRole')
            ? undefined
            : json['writeAccessRole'],
        readAccessRoles: !exists(json, 'readAccessRoles')
            ? undefined
            : json['readAccessRoles'],
        writeAccessRoles: !exists(json, 'writeAccessRoles')
            ? undefined
            : json['writeAccessRoles'],
    };
}

export function RestrictionToJSON(value?: Restriction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        readAccessRole: value.readAccessRole,
        writeAccessRole: value.writeAccessRole,
        readAccessRoles:
            value.readAccessRoles === undefined
                ? undefined
                : Array.from(value.readAccessRoles as Set<any>),
        writeAccessRoles:
            value.writeAccessRoles === undefined
                ? undefined
                : Array.from(value.writeAccessRoles as Set<any>),
    };
}
