import { ValueConstraint } from '../api/sentinel';
import CollectFieldData, {
    ContraintCaptionDefinition,
} from './CollectFieldData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getParameters(value: any) {
    if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
    ) {
        return { 0: value };
    }
    if (Array.isArray(value)) {
        return { ...value };
    }
    return value;
}

function getValue<T, K extends keyof T>(data: T, key: K) {
    return data[key];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replaceTokens(template: string, context: any): string {
    const matches = [...template.matchAll(/\{(.+?)\}/g)];
    let result = template;
    matches
        .filter(property => property[1] in context)
        .forEach(match => {
            result = result.replaceAll(match[0], getValue(context, match[1]));
        });
    return result;
}

export function caption(fieldData: CollectFieldData, constraintName: string) {
    return replaceTokens(
        fieldData.contraintsCaption[
            constraintName as keyof ContraintCaptionDefinition
        ],
        {
            title: fieldData.caption.title,
            ...getParameters(
                fieldData.dataPoint.valueConstraint[
                    constraintName as keyof ValueConstraint
                ],
            ),
        },
    );
}
