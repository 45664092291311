import { Change } from '../api/sentinel';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

export default function ChangeView({ changes }: { changes: Change[] }) {
    const { t } = useTranslation();

    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
    };

    const field = (
        fieldName: string,
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field: fieldName,
            headerName: t(`change_${fieldName}_label`),
            ...commonAttributes,
            ...options,
        };
    };

    const columns: GridColDef[] = [
        field('fieldName', {
            flex: 1.5,
            minWidth: 220,
            hideable: false,
            filterable: true,
            valueGetter: params => t(params.value),
        }),
        field('newValue', {
            flex: 1.5,
            minWidth: 220,
            hideable: false,
            filterable: true,
            renderCell: params => (
                <>
                    {params.value == null || params.value == ''
                        ? ''
                        : t(params.value)}
                </>
            ),
        }),
        field('oldValue', {
            flex: 1.5,
            minWidth: 220,
            hideable: false,
            filterable: true,
            renderCell: params => (
                <>
                    {params.value == null || params.value == ''
                        ? ''
                        : t(params.value)}
                </>
            ),
        }),
    ];

    return (
        <DataGrid
            density={'standard'}
            getRowId={(change: Change) => JSON.stringify(change)}
            columns={columns}
            rows={changes ? changes : []}
            disableRowSelectionOnClick
            hideFooter={changes.length <= 20}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'fieldName', sort: 'asc' }],
                },
            }}
        />
    );
}
