import Tooltip from '@mui/material/Tooltip';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

function ReadOnlyTooltip(props: {
    childDisabled: boolean;
    children: ReactNode;
}) {
    const { t: translate } = useTranslation();
    return (
        <>
            {props.childDisabled && (
                <Tooltip
                    title={translate('tooltip_read-only')}
                    placement={'left-start'}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'rgba(128, 128, 128, 1)',
                            },
                        },
                    }}
                >
                    <span>{props.children}</span>
                </Tooltip>
            )}
            {!props.childDisabled && props.children}
        </>
    );
}

export default ReadOnlyTooltip;
