import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    AppBar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material';
import ActionButton from './formContextBoundControls/ActionButton';
import ButtonsGroup from './formContextBoundControls/ButtonsGroup';
import { useTranslation } from 'react-i18next';

const ModalAppBar = ({
    dialogBody,
    dialogTitle,
    onClose,
    showWarnings,
    title,
}: {
    dialogBody: string;
    dialogTitle: string;
    onClose: () => void;
    showWarnings: boolean;
    title: string;
}) => {
    const { t } = useTranslation();
    const translation = {
        btnCancel: t('topBar_cancelBtn'),
        btnDiscard: t('topBar_discardBtn'),
    };
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const confirm = () => {
        if (showWarnings) {
            setIsDialogOpen(true);
        } else {
            onClose();
        }
    };

    const cancel = () => {
        setIsDialogOpen(false);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        onClose();
    };

    return (
        <>
            <AppBar position="sticky" color="default" elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label="close"
                        size="small"
                        onClick={confirm}
                        data-testid="backButton"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography>{title}</Typography>
                </Toolbar>
            </AppBar>
            <Divider />
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" data-testid="dialogTitle">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        data-testid="dialogBody"
                    >
                        {dialogBody}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonsGroup type="CTAGroup">
                        <ActionButton
                            label={translation.btnCancel}
                            type="secondary"
                            onClick={cancel}
                            dataTest="button"
                            dataTestId="cancelButton"
                        />
                        <ActionButton
                            label={translation.btnDiscard}
                            type="primary"
                            onClick={handleClose}
                            dataTestId="closeButton"
                        />
                    </ButtonsGroup>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalAppBar;
