import { useTranslation } from 'react-i18next';

type ProjectToolbarProps = {
    title: string;
    dialogTitle: string;
    dialogBody: string;
};
type UseProjectCreating = {
    isCreating: true;
    toolbarProps: ProjectToolbarProps;
    returnPath: string;
};
type UseProjectUpdating = {
    isCreating: false;
    projectId: string;
    toolbarProps: ProjectToolbarProps;
    returnPath: string;
};
type UseProjectModeResult = UseProjectCreating | UseProjectUpdating;

const useProjectMode = (
    projectId: string | undefined,
): UseProjectModeResult => {
    const { t } = useTranslation();
    const translation = {
        createTitle: t('projectForm_create_title'),
        editTitle: t('projectForm_edit_title'),
        forgetTitle: t('projectForm_forgetProject_label'),
        unsavedChangeTitle: t('projectForm_unsavedChanges_label'),
        remove: t('projectForm_confirm_remove'),
        discardChange: t('projectForm_confirm_discardChange'),
    };

    if (projectId !== undefined) {
        return {
            projectId,
            isCreating: false,
            toolbarProps: {
                title: translation.editTitle,
                dialogTitle: translation.unsavedChangeTitle,
                dialogBody: translation.discardChange,
            },
            returnPath: `/Dashboard/${projectId}`,
        };
    } else {
        return {
            isCreating: true,
            toolbarProps: {
                title: translation.createTitle,
                dialogTitle: translation.forgetTitle,
                dialogBody: translation.remove,
            },
            returnPath: '/',
        };
    }
};

export default useProjectMode;
