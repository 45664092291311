import { useTranslation } from 'react-i18next';
import CollectFieldData, {
    ContraintCaptionDefinition,
    DataPointCaptionDefinition,
    DataPointDefinition,
} from '../collectors/CollectFieldData';

const contraintsCaption: ContraintCaptionDefinition = {
    required: '{title} must be provided',
    allowedAnyOfOrNone: '{title} needs any of {options} or none',
    allowedAnyOf: '{title} needs any of {options}',
    allowedOneOf: '{title} needs one of {options}',
    greaterThanOrEqualTo: '{title} must be greater than or equal to {0}',
    lessThanOrEqualTo: '{title} must be less than or equal to {0}',
};

const datapoints: DataPointDefinition[] = [
    {
        id: 'release',
        type: 'INTEGER',
        valueConstraint: {},
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'valueCommitted',
        type: 'INTEGER',
        category: 'AGILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'valueDelivered',
        type: 'INTEGER',
        category: 'AGILITY',
        valueConstraint: {
            required: false,
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'valueReady',
        type: 'INTEGER',
        category: 'AGILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'totalBacklogSize',
        type: 'INTEGER',
        category: 'AGILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'workItemsDelivered',
        type: 'INTEGER',
        category: 'AGILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'staticAnalysisRuleViolations',
        type: 'INTEGER',
        category: 'SOFTWARE_HABITABILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'totalIssuesOpen',
        type: 'INTEGER',
        category: 'QUALITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'totalIssuesNew',
        type: 'INTEGER',
        category: 'QUALITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'buildPassRate',
        type: 'PERCENTAGE',
        category: 'SOFTWARE_HABITABILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0.0,
            lessThanOrEqualTo: 100.0,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'unitTestCoverage',
        type: 'PERCENTAGE',
        category: 'SOFTWARE_HABITABILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0.0,
            lessThanOrEqualTo: 100.0,
        },
    },
    {
        id: 'maxCyclomaticComplexity',
        type: 'DECIMAL',
        category: 'SOFTWARE_HABITABILITY',
        valueConstraint: {
            greaterThanOrEqualTo: 0.0,
            lessThanOrEqualTo: 1000.0,
        },
    },
    {
        id: 'releaseCount',
        type: 'INTEGER',
        category: 'RELEASE',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'remediationCount',
        type: 'INTEGER',
        category: 'RELEASE',
        valueConstraint: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9999,
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'ciActivity',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        options: [
            'NONE',
            'COMPILES',
            'STATIC_ANALYSIS',
            'RUNS_TESTS',
            'GENERATES_ARTIFACT',
        ],
        valueConstraint: {
            allow: 'anyOfOrNone',
        },
    },
    {
        id: 'deploymentTarget',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        options: ['NONE', 'CI', 'TEST', 'UAT', 'STAGING', 'PROD'],
        valueConstraint: {
            allow: 'anyOfOrNone',
        },
    },
    {
        id: 'iterationGoalSucceeded',
        type: 'ENUMERATION',
        category: 'AGILITY',
        options: ['Y', 'N'],
        valueConstraint: {
            allow: 'oneOf',
        },
    },
    {
        id: 'staticAnalysis',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        options: ['Y', 'N'],
        valueConstraint: {
            allow: 'oneOf',
        },
    },
    {
        id: 'configurationManagement',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        options: ['Y', 'N'],
        valueConstraint: {
            allow: 'oneOf',
        },
    },
    {
        id: 'teamMorale',
        type: 'ENUMERATION',
        category: 'AGILITY',
        options: [
            'HAPPY',
            'SATISFIED',
            'INDIFFERENT',
            'DISSATISFIED',
            'UNHAPPY',
        ],
        valueConstraint: {
            allow: 'oneOf',
        },
    },
    {
        id: 'productionRelease',
        type: 'ENUMERATION',
        category: 'RELEASE',
        options: ['Y', 'N'],
        valueConstraint: {
            allow: 'oneOf',
        },
    },
    {
        id: 'engagementModel',
        type: 'ENUMERATION',
        category: 'PROJECT',
        options: ['OUTCOME', 'COMANAGED', 'CAPACITY', 'ASSESSMENT'],
        valueConstraint: {
            allow: 'oneOf',
        },
        restriction: {
            readAccessRoles: ['write', 'view', 'leader'],
            writeAccessRoles: ['leader', 'write'],
        },
    },
    {
        id: 'branchingStrategy',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        options: [
            'NONE',
            'MAIN_DEV',
            'GITFLOW',
            'FEATURE',
            'FEATURE_BRANCH_PER_RELEASE',
            'OTHER',
        ],
        valueConstraint: {
            allow: 'oneOf',
        },
    },
];

function adaptToLegacy(fieldData: CollectFieldData[]): CollectFieldData[] {
    setOptions(
        'deploymentTarget',
        ['NONE', 'CI', 'TEST', 'UAT', 'PROD_STAGING'],
        fieldData,
    );
    setOptions(
        'ciActivity',
        [
            'NONE',
            'COMPILES',
            'COMPILES_STATIC_ANALYSIS',
            'COMPILES_STATIC_ANALYSIS_RUNS_TESTS',
            'COMPILES_STATIC_ANALYSIS_RUNS_TESTS_GENERATES_ARTIFACT',
        ],
        fieldData,
    );
    return fieldData;
}

function setOptions(
    fieldId: string,
    options: string[],
    fieldData: CollectFieldData[],
) {
    fieldData
        .filter(data => data.dataPoint.id == fieldId)
        .forEach(data => {
            data.dataPoint.options = options;
            data.dataPoint.valueConstraint.allow = 'oneOf';
        });
}

const useDataPointDefinition = () => {
    const { t } = useTranslation();
    const tx = (key?: string) => {
        if (!key) return undefined;
        const result = t(key);
        return result !== key ? result : undefined;
    };

    function createCaption(id: string): DataPointCaptionDefinition {
        return {
            title: t(`sprintForm_${id}_label`),
            directiveText: tx(`sprintForm_${id}_helper`),
            help: tx(`sprintForm_${id}_tooltip`),
        };
    }

    const data: CollectFieldData[] = adaptToLegacy(
        datapoints.map(data => {
            return {
                dataPoint: data,
                contraintsCaption: contraintsCaption,
                caption: createCaption(data.id),
            };
        }),
    );
    return {
        data,
        isLoading: false,
    };
};

function throwException(message: string): CollectFieldData {
    throw new Error(message);
}

export function findFieldData(
    fieldData: CollectFieldData[],
    metricName: string,
): CollectFieldData {
    return (
        fieldData.find(f => f.dataPoint.id === metricName) ??
        throwException(`Field definition for metric ${metricName} not found`)
    );
}

export default useDataPointDefinition;
