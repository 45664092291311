import CollectFieldData from './CollectFieldData';
import * as yup from 'yup';
import { caption } from './CaptionTemplate';

type Schema =
    | yup.NumberSchema<number | undefined, yup.AnyObject, undefined, ''>
    | yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>;

const decimalValidation = yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('is-decimal', 'The value must be a valid decimal', (_, text) => {
        return (
            text.originalValue == undefined ||
            /^-?[0-9]+(\.[0-9]+)?$|^$/.test(text.originalValue)
        );
    });
const schemas = new Map(
    Object.entries({
        INTEGER: yup
            .number()
            .integer()
            .transform(value => (isNaN(value) ? undefined : value))
            .test(
                'is-integer',
                'The value must be a valid integer',
                (_, text) => {
                    return (
                        text.originalValue == undefined ||
                        /^-?[0-9]+$|^$/.test(text.originalValue)
                    );
                },
            ),
        DECIMAL: decimalValidation,
        PERCENTAGE: decimalValidation,
        ENUMERATION: yup.string(),
    }),
);

const functions = [required, minimum, maximum];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function exist(value: any) {
    return typeof value !== 'undefined';
}

function chooseSchema(fieldData: CollectFieldData) {
    return schemas.get(fieldData.dataPoint.type);
}

function generateFieldValidation(fieldData: CollectFieldData) {
    return new Map([
        [fieldData.dataPoint.id, generateSingleFieldSchema(fieldData)],
    ]);
}
function xor(left?: Schema, right?: Schema) {
    return (left && !right) || (!left && right);
}

function concat(left?: Schema, right?: Schema): Schema {
    if (xor(left, right)) {
        return left ?? right ?? yup.string();
    }
    if (left instanceof yup.NumberSchema && right instanceof yup.NumberSchema) {
        return right.concat(left);
    }
    if (left instanceof yup.StringSchema && right instanceof yup.StringSchema) {
        return right.concat(left);
    }
    throw new Error('Incompatible validation schema');
}

function generateSingleFieldSchema(fieldData: CollectFieldData) {
    const validations = functions
        .map(f => f(fieldData))
        .filter(value => value !== undefined);
    return !Array.isArray(validations) || !validations.length
        ? chooseSchema(fieldData)
        : validations.reduce((left, right) => concat(left, right));
}

function required(
    fieldData: CollectFieldData,
): yup.NumberSchema | yup.StringSchema | undefined {
    return fieldData?.dataPoint?.valueConstraint?.required
        ? chooseSchema(fieldData)?.required(
              fieldData.contraintsCaption.required.replace(
                  '{title}',
                  fieldData.caption.title,
              ),
          )
        : undefined;
}

function minimum(
    fieldData: CollectFieldData,
): yup.NumberSchema | yup.StringSchema | undefined {
    return exist(fieldData?.dataPoint?.valueConstraint?.greaterThanOrEqualTo)
        ? chooseSchema(fieldData)?.min(
              fieldData.dataPoint.valueConstraint.greaterThanOrEqualTo,
              caption(fieldData, 'greaterThanOrEqualTo'),
          )
        : undefined;
}

function maximum(
    fieldData: CollectFieldData,
): yup.NumberSchema | yup.StringSchema | undefined {
    return exist(fieldData?.dataPoint?.valueConstraint?.lessThanOrEqualTo)
        ? chooseSchema(fieldData)?.max(
              fieldData.dataPoint.valueConstraint.lessThanOrEqualTo,
              caption(fieldData, 'lessThanOrEqualTo'),
          )
        : undefined;
}

export default function generateValidations(fieldsData: CollectFieldData[]) {
    const validations = fieldsData.map(field => generateFieldValidation(field));
    return Object.fromEntries(validations.flatMap(map => [...map]));
}
