import { CategoryCaption } from '../collectors/CollectFieldData';

const categoryCaptions: CategoryCaption[] = [
    {
        id: 'ENVIRONMENT',
        iconUrl:
            'https://cdn.iconscout.com/icon/free/png-256/agile-1851563-1569283.png',
        title: 'Environment',
        description:
            'This is some description. <ul><li>some item</li><li>some other item</li></ul>',
    },
    {
        id: 'AGILITY',
        iconUrl:
            'https://cdn.iconscout.com/icon/free/png-256/agile-1851563-1569283.png',
        title: 'Agile Metrics',
        description:
            'This is some description. <ul><li>some item</li><li>some other item</li></ul>',
    },
    {
        id: 'QUALITY',
        iconUrl:
            'https://cdn.iconscout.com/icon/free/png-256/agile-1851563-1569283.png',
        title: 'Product Quality',
        description:
            'This is some description. <ul><li>some item</li><li>some other item</li></ul>',
    },
    {
        id: 'SOFTWARE_HABITABILITY',
        iconUrl:
            'https://cdn.iconscout.com/icon/free/png-256/agile-1851563-1569283.png',
        title: 'Code Quality',
        description:
            'This is some description. <ul><li>some item</li><li>some other item</li></ul>',
    },
    {
        id: 'RELEASE',
        iconUrl:
            'https://cdn.iconscout.com/icon/free/png-256/agile-1851563-1569283.png',
        title: 'Releases',
        description:
            'This is some description. <ul><li>some item</li><li>some other item</li></ul>',
    },
];

const useCategoryCaption = () => {
    return {
        data: categoryCaptions,
        isLoading: false,
    };
};

export default useCategoryCaption;
