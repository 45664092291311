import { ProjectFormModel } from './projectFormModel';
import { DataPointDefinition, Location, Project } from '../../api/sentinel';

const dtoToForm = (
    projectId: string,
    projectData: Project,
    projectDataPoints: DataPointDefinition[],
): ProjectFormModel => {
    const {
        active,
        businessId,
        clientName,
        commentary,
        endDate,
        locations,
        engagementManager,
        projectName,
        startDate,
        engagementModel,
        scrumMaster,
        practicePartner,
        projectLinks,
        updatedBy,
        updatedOn,
    } = projectData;
    return {
        activeProjectSwitch: active ?? false,
        businessId: businessId ?? '',
        clientName,
        commentary: commentary ?? '',
        engagementManager: engagementManager ?? {},
        endDate,
        locations: Array.from(locations ?? [])
            .map(l => l.locationId ?? 0)
            .map(value => String(value)),
        dataPointIds: Array.from(projectDataPoints ?? []).map(
            dataPoint => dataPoint.id ?? '',
        ),
        projectId,
        projectName,
        startDate,
        engagementModel: engagementModel ?? '',
        scrumMaster: scrumMaster ?? {},
        practicePartner: practicePartner ?? {},
        projectLinks: projectLinks ?? [],
        updatedBy,
        updatedOn,
    };
};

const formToProjectDto = (
    values: ProjectFormModel,
    locationObjects: Location[],
) => {
    const locations = locationObjects.filter(l =>
        values.locations.includes(String(l.locationId)),
    );
    return {
        active: values.activeProjectSwitch,
        businessId: values.businessId,
        clientName: values.clientName,
        commentary: values.commentary,
        endDate: values.endDate,
        locations: new Set<Location>(locations),
        engagementManager: values.engagementManager,
        projectId: values.projectId,
        projectName: values.projectName,
        startDate: values.startDate,
        engagementModel:
            values.engagementModel === '' ? null : values.engagementModel,
        scrumMaster: values.scrumMaster,
        practicePartner: values.practicePartner,
        projectLinks: values.projectLinks,
    } as Project;
};

const formToDataPointsDto = (values: ProjectFormModel) => {
    return Array.from(values.dataPointIds ?? []).map(
        value => ({ id: value }) as DataPointDefinition,
    );
};

export { dtoToForm, formToProjectDto as formToDto, formToDataPointsDto };
