// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Restriction } from './Restriction';
import {
    RestrictionFromJSON,
    RestrictionFromJSONTyped,
    RestrictionToJSON,
} from './Restriction';

/**
 *
 * @export
 * @interface DataPointDefinition
 */
export interface DataPointDefinition {
    /**
     *
     * @type {string}
     * @memberof DataPointDefinition
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DataPointDefinition
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof DataPointDefinition
     */
    category?: string;
    /**
     *
     * @type {Set<string>}
     * @memberof DataPointDefinition
     */
    options?: Set<string>;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof DataPointDefinition
     */
    valueConstraint?: { [key: string]: any };
    /**
     *
     * @type {Restriction}
     * @memberof DataPointDefinition
     */
    restriction?: Restriction;
}

/**
 * Check if a given object implements the DataPointDefinition interface.
 */
export function instanceOfDataPointDefinition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataPointDefinitionFromJSON(json: any): DataPointDefinition {
    return DataPointDefinitionFromJSONTyped(json, false);
}

export function DataPointDefinitionFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): DataPointDefinition {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        type: !exists(json, 'type') ? undefined : json['type'],
        category: !exists(json, 'category') ? undefined : json['category'],
        options: !exists(json, 'options') ? undefined : json['options'],
        valueConstraint: !exists(json, 'value-constraint')
            ? undefined
            : json['value-constraint'],
        restriction: !exists(json, 'restriction')
            ? undefined
            : RestrictionFromJSON(json['restriction']),
    };
}

export function DataPointDefinitionToJSON(
    value?: DataPointDefinition | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        type: value.type,
        category: value.category,
        options:
            value.options === undefined
                ? undefined
                : Array.from(value.options as Set<any>),
        'value-constraint': value.valueConstraint,
        restriction: RestrictionToJSON(value.restriction),
    };
}
