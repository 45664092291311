import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import tokenService from '../../authentication/tokenService';
import MoraleInput from '../../common/formContextBoundControls/MoraleInput';
import SelectInput from '../../common/formContextBoundControls/SelectInput';
import SingleCheckbox from '../../common/formContextBoundControls/SingleCheckbox';
import TextFieldInput from '../../common/formContextBoundControls/TextFieldInput';
import { ValueConstraint } from '../../api/sentinel';
import Collector from '../../collectors/Collector';
import CollectFieldData from '../../collectors/CollectFieldData';
import { findFieldData } from '../../services/useDataPointDefinition';

type ComponentProps<T extends (props: Parameters<T>[0]) => JSX.Element> = Omit<
    Parameters<T>[0],
    'name' | 'label' | 'tooltip_title' | 'helper_text'
>;

export type TextMetricData = {
    type: 'text';
    valueConstraint?: ValueConstraint;
} & ComponentProps<typeof TextFieldInput>;
export type MoraleMetricData = {
    type: 'morale';
    valueConstraint?: ValueConstraint;
} & ComponentProps<typeof MoraleInput>;

type SelectInputProps = Omit<ComponentProps<typeof SelectInput>, 'options'>;
export type SelectMetricData = {
    type: 'select';
    options: string[];
    valueConstraint?: ValueConstraint;
} & SelectInputProps;

export type CheckboxMetricData = {
    type: 'checkbox';
    valueConstraint?: ValueConstraint;
} & ComponentProps<typeof SingleCheckbox>;

export type IntegerMetricData = {
    type: 'integer';
    valueConstraint?: ValueConstraint;
} & ComponentProps<typeof TextFieldInput>;

export type DecimalMetricData = {
    type: 'decimal';
    valueConstraint?: ValueConstraint;
} & ComponentProps<typeof TextFieldInput>;

export type MetricData =
    | TextMetricData
    | IntegerMetricData
    | DecimalMetricData
    | MoraleMetricData
    | SelectMetricData
    | CheckboxMetricData;

export type MetricsCategoryData = {
    icon: (props: Record<string, never>) => ReactNode;
    metrics: Record<string, MetricData>;
};

const MetricCategoryForm = ({
    category,
    metrics,
    fieldsData,
}: {
    category: string;
    metrics: MetricsCategoryData;
    fieldsData: CollectFieldData[];
}) => {
    const { t } = useTranslation();
    const tx = (key?: string) => {
        if (!key) return undefined;
        const result = t(key);
        return result !== key ? result : undefined;
    };
    const categoryTranslation = {
        title: t(`sprintForm_${category.toLowerCase()}_title`),
        intro: tx(`sprintForm_${category.toLowerCase()}_intro`),
    };

        return (
        <>
            <Stack
                alignItems="start"
                direction="row"
                justifyContent="flex-start"
                spacing={1}
                sx={{ marginBottom: '0.4rem' }}
            >
                <metrics.icon />
                <Typography variant="h3" component="h3">
                    {categoryTranslation.title}
                </Typography>
            </Stack>
            {categoryTranslation.intro && (
                <Typography component="div" variant="body2">
                    <Markdown>{categoryTranslation.intro}</Markdown>
                </Typography>
            )}

            <Grid container spacing={4} sx={{ marginBottom: '2rem' }}>
                {Object.entries(metrics.metrics).map(([metricName, metric]) => {
                    const metricTranslation = {
                        label: t(`sprintForm_${metricName}_label`),
                        tooltip_title: tx(`sprintForm_${metricName}_tooltip`),
                        helper_text: tx(`sprintForm_${metricName}_helper`),
                    };
                    return (
                        <Grid key={metricName} item xs={12} sm={6}>
                            {metricName != 'iterationGoalSucceeded' 
                                && ['integer', 'decimal', 'select'].includes(metric.type) 
                                && (
                                <Collector
                                    hasRole={tokenService.isAllowed}
                                    fieldData={findFieldData(fieldsData, metricName)}
                                />
                            )}
                            {metric.type === 'text' && (
                                <TextFieldInput
                                    label={metricTranslation.label}
                                    name={metricName}
                                    tooltip_title={
                                        metricTranslation.tooltip_title
                                    }
                                    helper_text={metricTranslation.helper_text}
                                    disabled={!tokenService.hasEditingRights()}
                                />
                            )}
                            {metric.type === 'morale' && (
                                <MoraleInput
                                    label={metricTranslation.label}
                                    name={metricName}
                                    tooltip_title={
                                        metricTranslation.tooltip_title
                                    }
                                    disabled={!tokenService.hasEditingRights()}
                                />
                            )}
                            {metric.type === 'checkbox' && (
                                <SingleCheckbox
                                    label={metricTranslation.label}
                                    name={metricName}
                                    tooltip_title={
                                        metricTranslation.tooltip_title
                                    }
                                    disabled={!tokenService.hasEditingRights()}
                                />
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default MetricCategoryForm;
