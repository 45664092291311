import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';

function ButtonsGroup(props: {
    type: 'CTAGroup' | 'iconsList';
    children: ReactNode;
}) {
    return <Stack className={props.type}>{props.children}</Stack>;
}

export default ButtonsGroup;
