import type { Metric, Sprint } from '../../api/sentinel';
import { SprintSprintStatusEnum } from '../../api/sentinel/openapi/models';
import type { MetricSpec } from '../../services/useProvidedMetricSpecs';

export type SprintFormMetadataModel = {
    commentary?: string;
    endDate: Date;
    isSprintCompleted?: boolean;
    name: string;
    projectId?: string;
    sprintGoal?: string;
    sprintId?: number;
    sprintNo: number;
    startDate: Date;
};
export type SprintFormDataModel = Record<
    string,
    number | string | Date | boolean | undefined
>;
export type SprintFormModel = SprintFormMetadataModel & SprintFormDataModel;

function metricToValue(
    metric: Metric,
    spec: MetricSpec,
): number | boolean | string | undefined {
    switch (spec.type) {
        case 'INTEGER':
        case 'DECIMAL':
            return metric as number;
        case 'TEAM_MORALE': {
            const map = new Map([
                ['HAPPY', 5],
                ['SATISFIED', 4],
                ['INDIFFERENT', 3],
                ['DISSATISFIED', 2],
                ['UNHAPPY', 1],
            ]);
            return map.get(metric as string) || undefined;
        }
        case 'YES_NO':
            return (metric ?? '') as string;
        default:
            return metric as string;
    }
}

const dtoToForm = (
    sprint: Partial<Sprint>,
    metrics: Record<string, Metric>,
    specs: Record<string, MetricSpec>,
): SprintFormModel => {
    const metaData = {
        commentary: sprint.commentary,
        endDate: sprint.sprintEndDate,
        isSprintCompleted:
            sprint.sprintStatus === SprintSprintStatusEnum.Completed,
        name: sprint.name,
        sprintGoal: sprint.sprintGoal,
        sprintId: sprint.sprintId,
        sprintNo: sprint.sprintNo,
        startDate: sprint.sprintStartDate,
    } as SprintFormMetadataModel;

    const data = Object.fromEntries(
        Object.entries(specs).map(([key, spec]) => [
            key,
            metricToValue(metrics[key], spec),
        ]),
    ) as SprintFormDataModel;

    return {
        ...data,
        ...metaData,
    };
};

const formToDto = (data: SprintFormModel): Sprint => {
    return {
        commentary: data.commentary,
        name: data.name,
        sprintEndDate: data.endDate,
        sprintGoal: data.sprintGoal,
        sprintNo: data.sprintNo,
        sprintStartDate: data.startDate,
        sprintStatus: data.isSprintCompleted
            ? SprintSprintStatusEnum.Completed
            : SprintSprintStatusEnum.Draft,
    };
};

export { dtoToForm, formToDto };
