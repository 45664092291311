import Button from '@mui/material/Button';

function ActionButton(props: {
    label: string;
    onClick: () => void;
    type: 'primary' | 'secondary';
    dataTestId?: string;
    dataTest?: string;
    disabled?: boolean;
}) {
    return (
        <Button
            variant={props.type === 'primary' ? 'contained' : 'outlined'}
            color={props.type}
            onClick={props.onClick}
            data-testid={props.dataTestId}
            data-test={props.dataTest}
            disabled={props.disabled}
        >
            {props.label}
        </Button>
    );
}

export default ActionButton;
